
<template>
  <!-- 会员商城体系 -->
  <div>
    <div class="heading">
      <h3>会员商城体系</h3>
      <p>沉淀私域流量，打造零售业务闭环，帮助企业打造生态级的社群营销体系</p>
      <button @click="onapply()">申请试用</button>
    </div>
    <div class="content">
      <div class="cost">
        <h4 class="head">微心动会员商城体系产品价值</h4>
        <p class="slogan">
          搭建企业直销商城，打造数字化直销业务，沉淀品牌私域流量，实现品牌与消费者连接
        </p>
        <div class="cost_c">
          <div>
            <img src="../../assets/images/cost01.png" />
            <h6>多终端B2C线上商城</h6>
            <p>
              覆盖小程序、H5微商城、PC端、APP商城<br />
              等多终端触点。功能完善，交互友好<br />
              可承载千万级访问量<br />
              帮助企业快速搭建 <br />
              以消费者为中心的商城体系
            </p>
          </div>
          <div>
            <img src="../../assets/images/cost02.png" />
            <h6>线上线下融合</h6>
            <p>
              线上＋线下的营销方式相结合<br />
              企业线上页面可根据运营需要<br />
              配置不同风格和内容，实现多场景满足<br />
              客户的个性化需要<br />
              打造线上＋线下的完美闭环
            </p>
          </div>
          <div>
            <img src="../../assets/images/cost03.png" />
            <h6>多种营销玩法</h6>
            <p>
              创新性营销引擎，支持近100种促销规则<br />
              支持满减、满赠、优惠券、限时抢购、<br />
              预售、拼团、、储值卡、礼品卡<br />
              等多种营销方式，营销活动和会员资产和<br />
              线下门店打通，全面满足运营需要
            </p>
          </div>
          <div>
            <img src="../../assets/images/cost04.png" />
            <h6>统一高效运营</h6>
            <p>
              支持商品、订单、会员、营销、<br />
              库存、服务，<br />
              统一化运营管理<br />
              可快速建立一整套<br />
              数字化商业管理系统
            </p>
          </div>
        </div>
      </div>
      <div class="demand">
        <h4 class="head">满足多样化的零售场景需求</h4>
        <p class="slogan">
          微心动会员商城系统（直销商城系统），结合多种业务场景，玩转私域流量
        </p>
        <div class="demand_c">
          <div class="demand_t">
            <img src="../../assets/images/demand01.png" />
            <div>
              <h6>1.在线商城+门店转化场景</h6>
              <p>
                <img
                  src="../../assets/images/demand03.png"
                  style="width: 0.16rem; height: 0.16rem"
                />
                <span
                  >顾客扫描员工专属二维码，关注企业公众号同时和员工绑定，顾客在公众号里的咨询都会直接传递到所绑定的员工，为消费者提供专属服务。</span
                >
              </p>
              <p>
                <img
                  src="../../assets/images/demand03.png"
                  style="width: 0.16rem; height: 0.16rem"
                />
                <span
                  >服务顾客所产生的订单归属于绑定的员工，获得相应提成。提升员工的销售热情，丰富销售场景。</span
                >
              </p>
              <p>
                <img
                  src="../../assets/images/demand03.png"
                  style="width: 0.16rem; height: 0.16rem"
                />
                <span
                  >支持对顾客粉丝分组标签实施精细化管理，针对不同的客户来源，及客户的购买行为进行有针对性的差异化营销，增强会员粘性，提升更多复购机会。</span
                >
              </p>
            </div>
          </div>
          <div class="demand_t">
            <div>
              <h6>2.在线商城+社交分销场景</h6>
              <p>
                <img
                  src="../../assets/images/demand03.png"
                  style="width: 0.16rem; height: 0.16rem"
                />
                <span
                  >通过社交网络关系链建立信用体系，促进商品传播、产品销售，实现爆炸式口碑营销,形成裂变式发展。</span
                >
              </p>
              <p>
                <img
                  src="../../assets/images/demand03.png"
                  style="width: 0.16rem; height: 0.16rem"
                />
                <span
                  >利用优惠或佣金的吸引力，消费者、粉丝、伙伴都可以成为企业的推客（分销员），依靠熟人经济，引爆社交关系链。</span
                >
              </p>
              <p>
                <img
                  src="../../assets/images/demand03.png"
                  style="width: 0.16rem; height: 0.16rem"
                />
                <span
                  >佣金结算方式灵活，由销售提成、业绩奖励、团队奖励等组成，分销员依据规则获得返佣。助力企业快速建立分销体系，开启业绩自增长模式，提升销售。</span
                >
              </p>
              <p>
                <img
                  src="../../assets/images/demand03.png"
                  style="width: 0.16rem; height: 0.16rem"
                />
                <span
                  >为每位分销员生成专属的二维码，消费者通过扫描二维码进行注册，注册客户记录在该二维码所有者层级下。</span
                >
              </p>
            </div>
            <img src="../../assets/images/demand02.png" />
          </div>

          <div class="demand_d">
            <h6>3.线下转化场景</h6>
            <p>
              <img
                src="../../assets/images/demand03.png"
                style="width: 0.16rem; height: 0.16rem"
              />
              <span
                >连通线上线下能力，线上线下相互赋能，重构消费体验、销售方式及销售场景，赋能品牌商及门店业务。</span
              >
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["childEvent"],
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {
    onapply(){
        // this.childEvent(true);
        window.location.href = "http://xdnmxba7z7d6llfo.mikecrm.com/uMx7QGf";
      }
  },
};
</script>

<style scoped lang="less">
.heading {
  height: 6.6rem;
  background-image: url(../../assets/images/Banner_3.png);
  background-size: 100% 100%;
  text-align: center;
  h3 {
    font-size: 0.56rem;
    padding-top: 2.05rem;
  }
  p {
    font-size: 0.2rem;
    line-height: 0.8rem;
  }
  button {
    width: 1.48rem;
    height: 0.64rem;
    background-color: #ffc53d;
    border: 0;
    color: #fff;
    font-size: 0.2rem;
    margin-top: 0.4rem;
    border-radius: 0.05rem;
  }
}
.content {
  .cost {
    padding: 1.1rem 0;
    .cost_c {
      padding: 0.58rem 1.5rem 0;
      display: flex;
      justify-content: space-between;
      div {
        width: 4rem;
        text-align: center;
        img {
          width: 0.8rem;
          height: 0.8rem;
        }
        h6 {
          font-size: 0.22rem;
          padding-bottom: 0.3rem;
        }
        p {
          font-size: 0.18rem;
          line-height: 0.24rem;
          color: #666;
        }
      }
    }
  }
  .demand {
    padding: 0 1.5rem;
    .demand_c {
      padding-top: 0.58rem;
      .demand_t {
        display: flex;
        justify-content: center;
        padding-bottom: 0.3rem;
        img {
          padding-left: 0.3rem;
          width: 5.98rem;
          height: 3.6rem;
        }
        div {
          padding: 0.5rem 1.19rem 0 1rem;
          width: 6.8rem;
          h6 {
            font-size: 0.28rem;
            padding-left: 0.66rem;
            padding-bottom: 0.4rem;
          }
          p {
            font-size: 0.18rem;
            color: #666;
            display: flex;
            padding-bottom: 0.2rem;

            span {
              padding-left: 0.2rem;
              line-height: 0.24rem;
            }
          }
        }
      }
      .demand_t:nth-child(2){
          div{
              padding-left: 0rem;
          }
      }
      .demand_d {
        padding: 0.5rem 1.19rem 1rem 1.2rem;
        h6 {
          font-size: 0.28rem;
          padding-left: 0.36rem;
          padding-bottom: 0.4rem;
        }
        p {
          font-size: 0.16rem;
          color: #666;
          display: flex;
          padding-bottom: 0.2rem;

          span {
            padding-left: 0.2rem;
            line-height: 0.24rem;
          }
        }
      }
    }
  }
}

.head {
  font-size: 0.28rem;
  text-align: center;
  padding-bottom: 0.2rem;
}
.slogan {
  font-size: 0.18rem;
  color: #666;
  text-align: center;
}
</style>
